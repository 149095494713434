
import { defineComponent } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { useRoute, useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router = useRouter()
    const go = (i: any) => {
      // console.log(111);

      router.push(i)
    }
    return {
      go,
      Search,
    }
  },
})
