import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c23f9088"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "productlist" }
const _hoisted_2 = { class: "productlist-box" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "commodity-box" }
const _hoisted_5 = { class: "box-img" }
const _hoisted_6 = { class: "img" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "panel-data" }
const _hoisted_9 = {
  class: "style-number",
  style: {"text-align":"center"}
}
const _hoisted_10 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.name), 1),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.goodslist.rows, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "box",
            key: item.goodsDetailsId
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: item.picUrl
                }, null, 8, _hoisted_7)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(item.name), 1)
            ]),
            _createElementVNode("div", {
              class: "btn",
              onClick: ($event: any) => (_ctx.go(item.goodsDetailsId))
            }, "View", 8, _hoisted_10)
          ]))
        }), 128))
      ]),
      _createVNode(_component_el_pagination, {
        style: {"display":"flex","align-items":"center","justify-content":"center","margin-bottom":"100px"},
        "page-size": _ctx.pageSize,
        "pager-count": _ctx.pageNum,
        layout: "prev, pager, next",
        total: _ctx.total,
        onSizeChange: _ctx.handleSizeChange,
        onCurrentChange: _ctx.handleCurrentChange
      }, null, 8, ["page-size", "pager-count", "total", "onSizeChange", "onCurrentChange"])
    ])
  ]))
}