
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getGoodsList, getGoodsInfo } from "../api/page.js";
export default defineComponent({
  setup() {
    const state = reactive({
      type: "" as any,
      num: "2" as any,
      name: "" as any,
      pageSize: 40 as any,
      pageNum: 1 as any,
      goodslist: "" as any,
      total: 0 as any,
    });
    const route = useRoute();

    onMounted(() => {
      state.type = route.query.type;
      state.name = route.query.name;
      getgoodslist();
    });
    const getgoodslist = () => {
      var formdata = {
        goodsClassificationId: state.type,
        pageNum: state.pageNum,
        pageSize: state.pageSize,
      };
      getGoodsList(formdata).then((res: any) => {
        if (res.code == 200) {
          console.log(res);
          state.goodslist = res;
          state.pageNum = res.pages;
          state.total = res.total;
        }
      });
    };
    const handleSizeChange = (val: number) => {
      console.log(`${val} items per page`);
    };
    const handleCurrentChange = (val: number) => {
      // console.log(`current page: ${val}`);
      state.pageNum = val;
      getgoodslist();
    };
    const router = useRouter();
    const go = (id: any) => {
      getGoodsInfo(id).then((res) => {
        let goodsInfo = res.data
        router.push(
            `/customizer?id=${goodsInfo.goodsDetailsId}&svgz=${goodsInfo.svgFUrl}&svgf=${goodsInfo.svgBUrl}&havaCollar=${goodsInfo.havaCollar}&name=${goodsInfo.name}`
        )
      })
    };
    return {
      ...toRefs(state),
      go,
      handleSizeChange,
      handleCurrentChange,
    };
  },
});
