
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getGoodsInfo } from '../api/page.js'
export default defineComponent({
  setup() {
    const state = reactive({
      goodinfo: '' as any,
    })
    const router = useRouter()
    const route = useRoute()
    const go = (i: any, id: any, z, f, havaCollar, name) => {
      // console.log(111);

      router.push(
        `/${i}?id=${id}&svgz=${z}&svgf=${f}&havaCollar=${havaCollar}&name=${name}`
      )
    }
    onMounted(() => {
      let id = route.query.id
      getGoodsInfo(id).then((res) => {
        console.log(res)
        state.goodinfo = res.data
      })
    })
    return {
      ...toRefs(state),
      go,
    }
  },
})
